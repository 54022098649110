import useTranslation from "@/Composables/useTranslation.js";

export default {
    methods: {
        /**
         * Translate the given key.
         */
        __: useTranslation,
    },
};
