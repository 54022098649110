import { usePage } from '@inertiajs/vue3';

export default function (key, replace = {}, def = null) {
    var translation = usePage().props.language_strings[key] || def || key;

    Object.keys(replace).forEach(function (key) {
        translation = translation.replace(':' + key, replace[key])
    });

    return translation
};
